



















































	import { internet } from '@/utils/Internet';
	import Util from '@/utils/Util';
	import { Component, Mixins, Watch } from 'vue-property-decorator';
	import { utils, writeFile } from 'xlsx';
	import AppDialog from '@/components/util/AppDialog.vue';
	import AppBaseForm from '@/components/util/AppBaseForm.vue';
	import { Form } from '@/model/util/Form';
	import { EValidate } from '@/model/util/ValidationType';
	import { Icon } from '@/model/util/Icon';
	import MixinLogin from '@/mixing/MixinLogin.vue';
	import { ButtonDownload as Text } from '@/config/Text.json';
	import MixinMain from '@/mixing/MixinMain.vue';
	@Component({
		name: 'BottonDownload',
		components: {
			AppDialog,
			AppBaseForm,
			MixinLogin,
			MixinMain,
		},
	})
	export default class BottonDownload extends Mixins(MixinLogin, MixinMain) {
		public isLoading = false;
		public isDialog = false;
		public reset = false;
		public isEnabled = true;
		public buttonText = this.$t("ButtonDownload.exportButtonText");
		public titleDialog = this.$t("ButtonDownload.titleDialog");
		public forms: Array<Form> = [
			{
				type: 'date',
				label: this.$t("ButtonDownload.inputDateTo") as string,
				rules: [EValidate.REQUIRED],
				model: {
					name: 'dateTo',
					extra: '',
					value: this.getYesterday,
					isOpen: false,
				},
				required: true,
				field: 12,
			},
			{
				type: 'date',
				label: this.$t("ButtonDownload.inputDateEnd") as string,
				rules: [EValidate.REQUIRED],
				model: {
					name: 'dateEnd',
					extra: '',
					value: new Date().toISOString().slice(0, 10),
					isOpen: false,
				},
				required: true,
				field: 12,
			},
		];
    getFilter: any;
		
		mounted() {
			for (let permission of this.getPermission) {
				if (permission.rsname === 'Exportar archivos') {
					this.isEnabled = true;
					break;
				}
			}
		}
		
		public getData(dateTo: string, dateEnd: string): void {
			const filter:Array<string> = [];
			filter.push(`FiltrFechDesde=${dateTo}`);
			filter.push(`FiltrFechHasta=${dateEnd}`);
			if (this.getFilter!.camping !== undefined) {
				for (const item of this.getFilter!.camping) {
					filter.push(
						`FiltrCampings[]=${(item as string)}`
					);
				}
			}
			const filterUrl = filter.join('&');
			this.isLoading = true;
			const request_1 = internet
				.newRequest()
				// .get('exportable2');
				.get(`exportable2?${filterUrl}`);
			const request_2 = internet
				.newRequest()
				// .get('exportable3');
				.get(`exportable3?${filterUrl}`);
			const request_3 = internet
				.newRequest()
				// .get('exportable1');
				.get(`exportable1?${filterUrl}`);
			const request_4 = internet
				.newRequest()
				.get(`exportable4?${filterUrl}`)
			const request_5 = internet
				.newRequest()
				.get(`exportable5?${filterUrl}`)
			const request_6 = internet
				.newRequest()
				.get(`exportable6?${filterUrl}`)
			Util.waitForPromises([request_1, request_2, request_3, request_4, request_5, request_6])
				.then((responses) => {
					const data_1 = responses[0].data;
					const data_2 = responses[1].data;
					const data_3 = responses[2].data;
					const data_4 = responses[3].data;
					const data_5 = responses[4].data;
					const data_6 = responses[5].data;
					this.generateExport(
						data_1,
						data_2,
						data_3,
						data_4["data"],
						data_5["data"],
						data_6["data"],
						data_4["header"],
						data_5["header"],
						data_6["header"]
					);
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
		private get getYesterday(): string {
			const date: Date = new Date();
			date.setDate(date.getDate() - 1);
			return date.toISOString().slice(0, 10);
		}
		private generateExport(
			data_1: Array<Record<string, string>>,
			data_2: Array<Record<string, string>>,
			data_3: Array<Record<string, string>>,
			data_4: Array<Record<string, string>>,
			data_5: Array<Record<string, string>>,
			data_6: Array<Record<string, string>>,
			header_4: Array<string>,
			header_5: Array<string>,
			header_6: Array<string>
		) {
			const order_1 = [
				'FECHA_ENVIO',
				'ESTADO',
				'ID',
				'PERSON',
				'DATETIME',
				'DURATION_SEC',
				'ANI',
				'CALL_DIRECTION',
				'RECORDING_FILENAME',
				'CODIGO_GESTION',
				'COMENTARIO',
				'CLIENTE',
				'RUT_DEUDOR_DV',
				'CARTERA',
				'SCRIPT',
				'DESCR_SCRIPT',
			];
			const order_2 = [
				'CLIENTE_ALLOXENTRIC',
				'UNIDAD_NEGOCIO',
				'COD_SCRIPT',
				'NRO_EVALUACION',
				'NOMBRE EVALUADOR',
				'AGENTE',
				'FECHA_EVALUACION',
				'ID_LLAMADA',
				'FECHA_LLAMADA',
				'TIPO_LLAMADA',
				'EVALUACION_LLAMADA_TAS',
				'CATEGORIA',
				'SUBCATEGORIA',
				'EVALUACION_ROBOT',
				'AJUSTE_EVALUADOR',
				'ARGUMENTO_AJUSTE',
				'CONCLUSION_AJUSTE',
			];
			var order_3: Array<string> = [];
			if (data_3.length > 0) {
				order_3 = Object.keys(data_3[0]);
			}

			const result_1: Array<Record<
				string,
				string
			>> = data_1.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of order_1) {
						exit[
							Util.replaceTextWith(
								order,
								'_',
								' '
							).toUpperCase()
						] = obj[order.toLowerCase()];
					}
					return exit;
				}
			);
			const result_2: Array<Record<
				string,
				string
			>> = data_2.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of order_2) {
						exit[
							Util.replaceTextWith(
								order,
								'_',
								' '
							).toUpperCase()
						] = obj[order.toLowerCase()];
					}
					return exit;
				}
			);

			const result_3: Array<Record<
				string,
				string
			>> = data_3.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of order_3) {
						exit[
							Util.replaceTextWith(
								order,
								'_',
								' '
							).toUpperCase()
						] = obj[order];
					}
					return exit;
				}
			);
			
			const result_4: Array<Record<
				string,
				string
			>> = data_4.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of header_4) {
						exit[
							order.toUpperCase()
						] = obj[order];
					}
					return exit;
				}
			);

			const result_5: Array<Record<
				string,
				string
			>> = data_5.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of header_5) {
						exit[
							order.toUpperCase()
						] = obj[order];
					}
					return exit;
				}
			);

			const result_6: Array<Record<
				string,
				string
			>> = data_6.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of header_6) {
						exit[
							order.toUpperCase()
						] = obj[order];
					}
					return exit;
				}
			);

			const audio = utils.json_to_sheet(result_1);
			const anali = utils.json_to_sheet(result_2);
			const evaluation = utils.json_to_sheet(result_3);
			const evAgent = utils.json_to_sheet(result_4);
			const evCamping = utils.json_to_sheet(result_5);
			const motivoMora = utils.json_to_sheet(result_6);
			const book = utils.book_new();
			utils.book_append_sheet(
				book,
				audio,
				'Audio y Meta-data Recibidos'
			);
			utils.book_append_sheet(
				book,
				anali,
				'Analítica de Audios'
			);
			utils.book_append_sheet(
				book,
				evaluation,
				'Evaluaciones'
			);
			utils.book_append_sheet(
				book, 
				evAgent, 
				'Resumen por Agente'
			);
			utils.book_append_sheet(
				book, 
				evCamping, 
				'Resumen por Campaña'
			);
			utils.book_append_sheet(
				book, 
				motivoMora, 
				'Motivo de mora'
			);
			writeFile(book, 'Repositorio.xlsx');
		}
		public listenGetData(payload: Record<string, string>) {
			if (Util.isObjetoVacio(payload)) {
				Util.showMessage(
					this.$t("Exportable.mensaje") as string,
					Icon.WARNING
				);
				return;
			}
			this.getData(payload.dateTo, payload.dateEnd);
		}
	}
