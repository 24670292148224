

























































import { Component, Mixins } from 'vue-property-decorator';
import { Form } from '@/model/util/Form';
import { IEvCat } from '@/model/main/IEvCat';
import { EValidate } from '@/model/util/ValidationType';
import { FormDialog as Text } from '@/config/Text.json';
import AppBaseForm from '@/components/util/AppBaseForm.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IItemSelect } from '@/model/util/IItemSelect';
import { ICamping } from '@/model/main/ICamping';
import { IFilterData } from '@/model/main/IFilterData';
import LoginMixin from '@/mixing/MixinLogin.vue';
import MainMixing from '@/mixing/MixinMain.vue';
import { LOCALES, Locales } from "@/i18n/locales";
import { defaultLocale } from "@/i18n";

import { Icon } from '@/model/util/Icon';
@Component({
	name: 'FormDialog',
	components: {
		AppBaseForm,
	},
})
export default class FormDialog extends Mixins(
	LoginMixin,
	MainMixing
) {
	public categories: Array<IEvCat> = new Array<IEvCat>();
	public campings: Array<ICamping> = new Array<ICamping>();
	public loading = false;
	public resetForm = false;
	public welcomeText = this.$t("FormDialog.welcomeText");
	public confirmText = this.$t("FormDialog.confirmText");
	public title = this.$t("FormDialog.title");
	public subTitle = this.$t("FormDialog.subTitle");
	public locales_array = LOCALES;
	public locale_default = defaultLocale;
	public forms: Array<Form> = [
		{
			type: 'date',
			rules: [EValidate.REQUIRED],
			label: this.$t("FormDialog.inputDateTo") as string,
			model: {
				name: 'dateTo',
				extra: '',
				value: '',
				isOpen: false,
			},
			required: true,
			field: 12,
		},
		{
			type: 'date',
			rules: [EValidate.REQUIRED],
			label: this.$t("FormDialog.inputDateEnd") as string,
			model: {
				name: 'dateEnd',
				extra: '',
				value: '',
				isOpen: false,
			},
			required: true,
			field: 12,
		},
		{
			type: 'select-camp',
			rules: [EValidate.REQUIRED],
			label: this.$t("FormDialog.selectCamping") as string,
			model: {
				name: 'camping',
				extra: [],
				value: '',
			},
			required: true,
			field: 12,
			multiple: true, //agregado
		},
		{
			type: 'select',
			rules: [EValidate.REQUIRED],
			label: this.$t("FormDialog.selectEvtCat") as string,
			model: {
				name: 'categories',
				extra: [],
				value: '',
			},
			required: true,
			field: 12,
			multiple: true,
		},
	];
	public client = "";

	created(): void {
		this.client = this.getUser.groups[0];
		this.loading = true;
		const request_1 = internet.newRequest().get('vue-dashboardNuevo');
		const request_2 = internet.newRequest().get('vueNuevo');
		Util.waitForPromises([request_1, request_2])
			.then((result) => {
				//this.categories = result[0].data
				//	.datosEvCat as Array<IEvCat>;
				this.categories = this.getUniqueEvCat(result[0].data.datosEvCat as Array<IEvCat>);
				this.campings = result[1].data
					.colCam as Array<ICamping>;
				this.updateEvCat(this.categories);
				//this.updateCamping(this.campings);
				this.forms[3].model.extra = this.getItemEvCat;
				this.forms[3].model.value = this.getStringCat;
				this.forms[2].model.extra = this.getItemCamping;
				this.forms = JSON.parse(
					JSON.stringify(this.forms)
				);
				console.log(result);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				this.loading = false;
			});
	}
	public getUniqueEvCat(array: Array<IEvCat>): Array<IEvCat> {
		const uniqueEvCats: Array<IEvCat> = [];
		const evCatsNames: Array<string> = [];
		for (let item of array) {
			if (!evCatsNames.includes(item.categoria)) {
				evCatsNames.push(item.categoria);
				uniqueEvCats.push(item);
			}
		}
		return uniqueEvCats;
	}
	get getItemEvCat(): Array<IItemSelect> {
		const finded: Array<IItemSelect> = [];
		for (let item of this.categories) {
			// if (item.campania === this.campings[0].campania) {
			// 	finded.push({
			// 		text: item.categoria,
			// 		value: item.categoria,
			// 	});
			// }
			finded.push({
				text: item.categoria,
				value: item.categoria,
			});
		}

		return finded;
	}
	get getStringCat() : Array<string> {
		const finded: Array<string> = [];
		for (let item of this.categories) {
			if (! finded.includes(item.categoria)) {
				finded.push(item.categoria);
			}
		}

		return finded;
	}
	get getItemCamping(): Array<IItemSelect> {
		// return [
		// 	{
		// 		text: this.campings.campania,
		// 		value: this.campings.campania,
		// 	},
		// ];
		const finded: Array<IItemSelect> = [];
		for (let item of this.campings) {
			finded.push({
				text: item.campania,
				value: `${item.campania}@@${item.IDScript}`,
				disabled: false,
			})
		}
		return finded;
	}

	public onGetData(payload: IFilterData): void {
		if (Object.keys(payload).length > 0) {
			const arraySelected: Array<IEvCat> = [];
			for (let cate of this.categories) {
				for (let selected of payload.categories as Array<
					string
				>) {
					if (cate.categoria === selected) {
						arraySelected.push(cate);
					}
				}
			}
			const campsSelected: Array<ICamping> = [];
			for (let camp of this.campings) {
				for (let selected of payload.camping as Array<
					string
				>) {
					if (camp.campania === selected.split('@@')[0]) {
						campsSelected.push(camp);
					}
				}
			}
			console.log("Campanias Seleccionadas: ", campsSelected);
			this.updateCamping(campsSelected);
			payload.categories = arraySelected;
			//payload.camping = ["Seleccione"];
			console.log(payload);
			this.updateFilter(payload);
		} else {
			Util.showMessage(Text.infoMessage, Icon.INFO);
		}
	}

	public updateLanguage(lang: Locales) {
		console.log("cambiando idioma a ");
		this.$emit('changeEvent', lang);
	}

	// public onGetDataCamp(payload: IFilterData): void {
	// 	if (Object.keys(payload).length > 0) {
	// 		const arraySelected: Array<IEvCat> = [];
	// 		for (let camp of this.campings) {
	// 			for (let selected of payload.camping as Array<
	// 				string
	// 			>) {
	// 				if (camp.campania === selected) {
	// 					arraySelected.push(camp);
	// 				}
	// 			}
	// 		}
	// 		payload.camping = arraySelected;
	// 		console.log(payload);
	// 		this.updateFilter(payload);
	// 	} else {
	// 		Util.showMessage(Text.infoMessage, Icon.INFO);
	// 	}
	// }
}

