












































import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import { MainMenu as Text } from '@/config/Text.json';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IevTipLlamCatCliente } from '@/model/main/IEvTipLlamCatCliente';
import { AxiosResponse } from 'axios';
import { IEvCampaCateg } from '@/model/main/IEvCampaCateg';
import { IEvCampaCliente } from '@/model/main/IEvCampaCliente';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';

@Component({
	name: 'TableProductCategory',
	components: {
		DataTable,
	},
})
export default class TableProductCategory extends MixinMain {
	public title = this.$t("MainMenu.titleTableProductCategory");
	public subTitle = '';
	public btnChangeCampaign = this.$t("MainMenu.btnChangeCampaign");
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.setCampings();
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_evTipLlamCateg = internet
			.newRequest()
			.get(
				`vue-xentric/evCampaCateg?${this.getFilterUrl}`
			);
		const request_evCampaCateg = internet
			.newRequest()
			.get(
				`vue-xentric/evClienteCateg?${this.getFilterUrl}`
			);
		const request_evCLientTotal = internet
			.newRequest()
			.get(
				`vue-xentric/evCarteraTotalCampa?${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_evTipLlamCateg,
			request_evCampaCateg,
			request_evCLientTotal
		])
			.then((responses) => {
				const response_1 = responses[0] as AxiosResponse;
				const evCampaCats: Array<IEvCampaCateg> = response_1.data as Array<
					IEvCampaCateg
				>;
				const reduce: Record<
					string,
					Array<IEvCampaCateg>
				> = Util.reduceByProperty<
					IEvCampaCateg,
					'campania'
				>(evCampaCats, 'campania');
				const order: Array<string> = reduce[
					Object.keys(reduce)[0]
				].map((e: IEvCampaCateg): string => {
					return e.categoria;
				});
				const result_2 = responses[2] as AxiosResponse;
				const total: Array<IEvCampaCliente> = result_2.data as Array<IEvCampaCliente>;

				const dataTables: Array<IDataTable> = [];
				for (let item of Object.keys(reduce)) {
					const row: IDataTable = {};
					let acum = 0;
					row['id'] = item;
					for (let label of order) {
						const result: IEvCampaCateg = reduce[
							item
						].find(
							(e) => e.categoria === label
						) as IEvCampaCateg;
						acum = acum += result.Resultado;
						row[label] = `${parseFloat(
							result.Resultado.toString()
						).toFixed(1)}%`;
					}

					// row['total'] = `${(
					// 	acum / order.length
					// ).toFixed(1)}%`;
					const porcent : IEvCampaCliente = total.find(
						(e) => e.campania === item
					) as IEvCampaCliente;
					row[this.$t("MainMenu.lastColumnProductCategory") as string] = `${(porcent.Resultado).toFixed(1)}%`;

					dataTables.push(row);
				}
				const firstElement: IDataTable =
					dataTables[0];
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(firstElement).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						if (text === 'id') {
							text = this.$t("MainMenu.firstColumnProductCategory") as string;
						}
						return {
							text,
							value: key,
						};
					}
				);
				this.header = header;
				this.rows = dataTables;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	private setCampings() : void {
		console.log("Seteando campanias...");
		const campsSelected : Array<string> = [];
		for (let camp of this.getCamping) {
			campsSelected.push(camp.campania as string)
		}
		let new_filter = this.getFilter;
		new_filter.camping = campsSelected;
		console.log("Nuevo filter: ", new_filter);
		this.updateFilter(new_filter);
	}

	public listenClickRow(payload: IDataTable) {
		var new_filter = this.getFilter;
		new_filter.camping = [payload.id];
		this.updateFilter(new_filter);
		this.$router.push({
			name: 'MainPortfolio',
			params: {
				portfolio: payload.id,
			},
		});
	}

	public change() {
		this.$emit('openDialog');
	}
}
